import * as React from 'react';
import { useContext } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Avatar, Divider, Stack, Typography } from '@mui/material';

import UserLogs from './UserLogs';
import avatar from '../../../assets/icons/avatarassistant.png';

import ChatContext from "../../../context/chat/chatContext";

export default function ChatBox() {
    const chatContext = useContext(ChatContext);
    const { assistant, category } = chatContext;

    return (
        <Card sx={{ borderRadius: 3, boxShadow: 3, height: '100%', marginBottom: 1, paddingBottom: '64px !important' }}>
            <CardContent sx={{ paddingX: { xs: '1rem', sm: 4 }, height: '100%', }}  >
                <Stack direction="row" spacing={1} borderRadius={2} marginBottom={'1rem'}  >
                    <Avatar alt="assistant" src={avatar}
                        sx={{ width: 56, height: 56, background: "#fff", marginLeft: 1 }} />
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom alignSelf={'center'}>
                        {assistant} - {category}
                    </Typography>
                </Stack>
                <Divider ></Divider>
                <UserLogs />
            </CardContent>
        </Card>

    );
}