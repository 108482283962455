import * as React from 'react';
import { useContext } from 'react';
import { useTheme } from '@emotion/react';

import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { Grid, Typography } from '@mui/material';

import avatar from '../../../assets/icons/avatarassistant.png';

import ChatContext from '../../../context/chat/chatContext';

export default function GreetMessage() {
    const theme = useTheme();
    const chatContext = useContext(ChatContext);
    const { assistant } = chatContext;

    return (
        <Grid container direction='column' marginBottom={2}
            display={assistant !== null ? 'none' : 'block'}>
            <Grid item>
                <Typography variant="h5" sx={{ lineHeight: 1, marginBottom: 1, fontWeight: 300, fontSize: { xs: '1rem', sm: '1.5rem' } }}>
                    AI Assistants
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="body1" sx={{ lineHeight: 1, fontSize: { xs: '0.8rem', sm: '1rem' } }} >
                    Chat with OpenAi assistants
                </Typography>
            </Grid>
            <Stack direction="row" spacing={6} padding={1} borderRadius={2} marginTop={2} marginBottom={{ xs: 2, sm: 4 }}
                sx={{ backgroundColor: 'rgba(236,176,253,0.15)', color: theme.palette.secondary.main, display: { xs: 'none', sm: 'flex' } }}>
                <Avatar alt="assistant" src={avatar}
                    sx={{ width: 56, height: 56, background: "#fff", marginLeft: 1 }} />
                <Typography variant='body2' alignSelf={'center'} >
                    You have 10 free requests left this month
                </Typography>
            </Stack>
        </Grid>
    );
}