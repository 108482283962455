import * as React from 'react';
import { Grid, Typography } from '@mui/material';

import OpenAiNavbar from './components/Navbar';
import ChatState from '../../context/chat/ChatState';
import ChatPage from './components/ChatPage';
import AiCategories from './components/AiCategories';

function ResponsiveAppBar() {
  return (
    <ChatState>
      <Grid container bgcolor={'#f9f9f9'} position={'relative'}>
        <OpenAiNavbar />
        <Grid container
          paddingTop={'5.5rem'}
          paddingBottom='2rem'
          paddingX={{ xs: '1rem', md: '2.5rem' }}
          minHeight={'100%'} >
          <Grid container gap={8} flexWrap={'nowrap'} >
            <Grid item sx={{ display: { xs: 'none', md: 'flex', } }}>
              <AiCategories />
            </Grid>
            <Grid item sx={{ width: '100%', height: '100%' }}>
              <ChatPage />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container sx={{
          borderTop: ' 1px solid #eaeaea',
          position: " absolute",
          bottom: 0,
          width: '100%',
          height: '4.5rem',
          display: 'flex',
          paddingLeft: 2,
          paddingRight: 2
        }} >
          <Typography variant="h6">
            Open AI 2023
          </Typography>
        </Grid>
      </Grid>
    </ChatState >

  );
}
export default ResponsiveAppBar;
