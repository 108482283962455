import { useContext, useState } from "react";

import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, } from "@mui/material";
import { Calculate, Explore, FitnessCenter, InterpreterMode, TheaterComedy, Translate, Headphones } from "@mui/icons-material"

import ChatContext from "../../../context/chat/chatContext";

const AiCategories = ({ handleDrawerToggle }) => {

    const chatContext = useContext(ChatContext);
    const { categories, selectedAssistants, setSelectedAssistants, getUserLogs, setAssistant, filterLogs, setCategory, clearAllLogs, } = chatContext;
    const [open, setOpen] = useState(true);

    //handle the dropdown menu for the categories to show the assistants
    const handleClick = (keys) => {
        open === keys ? setOpen(100) : setOpen(keys);
    };

    return (
        <List
            sx={{ width: '100%', fontFamily: 'Lato', fontSize: '0.7rem', padding: 0 }}
            component="nav"
            aria-labelledby="nested-list-subheader" >
            <ListItem sx={{ textTransform: 'uppercase', paddingTop: 0, paddingLeft: 0 }}>
                Categories
            </ListItem>
            {categories ? categories.map((category, index) =>
            (
                //map through the categories array received in the API call and show the categories that can be selected
                <>
                    <ListItemButton key={index}
                        sx={{ textTransform: 'capitalize', fontFamily: 'Lato', fontSize: '0.8rem', paddingX: 0 }}
                        onClick={() => {
                            setSelectedAssistants(category)
                            setCategory(category)
                            handleClick(index)
                        }}>
                        <ListItemIcon sx={{ justifyContent: 'start', minWidth: "36px" }}>
                            {category.category === 'Fitness Trainer' ? (
                                <FitnessCenter sx={{ color: "#9a8aff" }} fontSize='medium' />
                            ) : category.category === 'Math Teacher' ? (
                                <Calculate sx={{ color: "#9a8aff" }} fontSize='medium' />
                            ) : category.category === 'Interviewer' ? (
                                <InterpreterMode sx={{ color: "#9a8aff" }} fontSize='medium' />
                            ) : category.category === 'English Translator' ? (
                                <Translate sx={{ color: "#9a8aff" }} fontSize='medium' />
                            ) : category.category === 'Travel Guide' ? (
                                <Explore sx={{ color: "#9a8aff" }} />
                            ) : category.category === 'Stand-up Comedian' ? (
                                <TheaterComedy sx={{ color: "#9a8aff" }} fontSize='medium' />
                            ) : ''
                            }
                        </ListItemIcon>
                        <ListItemText primary={category.category} sx={{ textWrap: "nowrap" }} />

                        {/* 
                        dropdown arrows are currently hidden , to be changed if preffered by customer
                        {open === index ? <ExpandLess fontSize='small' sx={{ color: "#9a8aff" }} sx={{ marginLeft: 2 }} /> 
                        : <ExpandMore fontSize='small' sx={{ color: "#9a8aff" }} sx={{ marginLeft: { md: 4, lg: 0 } }} />} */}
                    </ListItemButton>
                    <Collapse in={open === index} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {
                                //map through the categories array received in the API call and show the assistants that can be selected
                                selectedAssistants ? selectedAssistants.map((assistant, index) => (
                                    <ListItemButton key={assistant} sx={{ pl: 2, pr: 0, textWrap: "nowrap" }}
                                        onClick={(e) => {
                                            //clear state
                                            clearAllLogs()
                                            //set the assistant state to the selected assistant
                                            setAssistant(e.target.outerText);
                                            //get the logs for the selected assistant
                                            getUserLogs();
                                            //filter the logs for the selected assistant
                                            filterLogs(assistant);
                                            //close the drawer in mobile view
                                            handleDrawerToggle();
                                        }}
                                    >
                                        <ListItemIcon sx={{ justifyContent: 'start', minWidth: "36px" }}>
                                            <Headphones fontSize='small' sx={{ color: "#9a8aff" }} />
                                        </ListItemIcon>
                                        <ListItemText primary={assistant} />
                                    </ListItemButton>
                                )
                                ) : null
                            }
                        </List>
                    </Collapse>
                </>
            )
            ) : null
            }
        </List>
    )
}

export default AiCategories;