
import { useContext, useEffect, useState } from "react";
import axios from "axios";

import ChatContext from "../../../context/chat/chatContext";
import AuthContext from "../../../context/auth/AuthContext";

import { Card, CardContent, CircularProgress, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { ExpandCircleDown } from "@mui/icons-material";

import GreetMessage from "./GreetMessage";
import AssistantCard from "./AssistantCard";
import ChatBox from "./ChatBox";

const ChatPage = () => {
    const identifier = window.location.hostname;
    // const identifier = https://cgptpwa.gosocialdev.eu/;
    const chatContext = useContext(ChatContext);
    const { assistant, previousLog, category, previousLogconversationID, messages,
        conversationID, setChatMessages, setChatID, loading, setLoading } = chatContext;
    const [userInput, setUserInput] = useState('');
    const { apiUrl, token } = useContext(AuthContext);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            StartNewChat();
        }
    };

    //format time to 24 hour format
    const getCurrentTime = () => {
        const now = new Date();
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    // if there is a previous log, set the chat messages to the previous log
    useEffect(() => {
        if (previousLogconversationID !== null) {
            setChatMessages({
                type: 'SET_CHAT_MESSAGES',
                message:
                    previousLog.map(log => {
                        return {
                            assistant_category: log.category,
                            assistant_name: log.assistant_name,
                            conversation_id: log.conversation_id,
                            message: log.content,
                            role: log.role,
                            time: log.created_at,
                        }
                    })
            });
            // set the conversation id to the previous log conversation id
            setChatID(previousLogconversationID);
        }
    }, [previousLogconversationID]);

    // start a new chat or continue the previous chat if there is a previous log rendered in the chat box
    const StartNewChat = async () => {
        setLoading(true);

        const timestamp = getCurrentTime();

        //setup the new message to be sent to the api
        const newMessage = {
            assistant_category: category,
            assistant_name: assistant,
            conversation_id: conversationID,
            message: `${userInput}`,
            client_domain: identifier,
            role: 'user',
            time: timestamp,
        };
        //if there are no messages in the chat box, set the chat messages to the new message
        messages.length === 0 ? setChatMessages({
            type: 'SET_CHAT_MESSAGES',
            message: [newMessage],
        }) :
            //if there are messages in the chat box, add the new message to the existing messages
            setChatMessages({
                type: 'SET_CHAT_MESSAGES',
                message: [...messages[0], newMessage],
            })
        setUserInput('');

        //send the new message to the api
        const response = async () => {
            await axios
                .post(
                    `${apiUrl}/open-ai/message`,
                    newMessage,
                    {
                        params: {},
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((response) => {
                    setChatID(response.data.conversation_id);
                    //format the response message to be rendered in the chat box
                    const responseMessage = {
                        assistant_category: category,
                        assistant_name: assistant,
                        conversation_id: conversationID,
                        message: `${response.data.message}`,
                        client_domain: identifier,
                        role: 'assistant',
                        time: timestamp,
                    }
                    //if there are no messages in the chat box, set the chat messages to the new message and response message
                    messages.length === 0 ? setChatMessages({
                        type: 'SET_CHAT_MESSAGES',
                        message: [newMessage, responseMessage],
                    }) :
                        //if there are messages in the chat box, add the new message and response message to the existing messages
                        setChatMessages({
                            type: 'SET_CHAT_MESSAGES',
                            message:
                                [...messages[0], newMessage, responseMessage],
                        })
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        };
        response();
    }

    //hide the chat box if there are no messages rendered in the chat box
    const hiddenChatBox = () => messages && messages.length === 0 && (previousLog === null || previousLog.length === 0) ? true : false

    return (
        <Grid container flexDirection={'column'} flexWrap={'nowrap'} sx={{ height: '100%' }}  >
            <Grid item>
                <GreetMessage />
            </Grid>
            <Grid item container gap={3} flexWrap={'nowrap'} flexDirection={{ xs: 'column', sm: 'row', }}
                alignItems={{ xs: 'center', sm: 'initial' }} >
                <Grid item xs={12}
                    sm={hiddenChatBox() ? 6 : 3}
                    lg={hiddenChatBox() ? 3 : 2}
                    xl={hiddenChatBox() ? 2 : 2}
                >
                    <AssistantCard StartNewChat={StartNewChat} hiddenChatBox={hiddenChatBox} />
                </Grid>
                <Grid item container sx={{
                    minHeight: hiddenChatBox() ? '65vh' : '75vh',
                    maxHeight: hiddenChatBox() ? '65vh' : '75vh',
                    width: '100%'
                }}
                    visibility={hiddenChatBox() ? 'hidden' : 'visible'}
                >
                    <Grid item container flexDirection={'column'} flexWrap={'nowrap'}
                        sx={{
                            height: '100%',
                            width: '100%',
                        }}>
                        <ChatBox />
                        <Card variant="outlined" sx={{ borderRadius: 3, boxShadow: 3, width: '100%', height: 'auto' }}>
                            <CardContent sx={{ padding: 2 }} >
                                <TextField
                                    variant="standard"
                                    onKeyDown={handleKeyPress}
                                    id="input-with-icon-textfield"
                                    label={previousLog && previousLog.length > 0 ? ' Continue your conversation' : 'Message'}
                                    value={userInput}
                                    onChange={(e) => setUserInput(e.target.value)}
                                    sx={{
                                        width: '100%', padding: 2
                                    }}
                                    InputProps={{
                                        disableUnderline: true,
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton
                                                    onClick={StartNewChat}
                                                    sx={{
                                                        '&:hover': {
                                                            backgroundColor: 'transparent'
                                                        },
                                                    }}
                                                >
                                                    {//set loading icon while waiting for response from api
                                                        loading ? (
                                                            <CircularProgress
                                                                color="inherit"
                                                                size="30px"
                                                            />
                                                        ) : (
                                                            <ExpandCircleDown
                                                                fontSize="large"
                                                                sx={{
                                                                    color: 'secondary.main',
                                                                    rotate: '270deg',
                                                                }}
                                                            />
                                                        )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}

                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >

    )
};

export default ChatPage;