import React, {
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import InputMask from 'react-input-mask';
import OpenAiNavbar from '../pages/chat/components/Navbar'
import Avatar from '@mui/material/Avatar';
import AuthContext from '../context/auth/AuthContext';
import {
  Box,
  Container,
  Grid,
  TextField,
  CircularProgress,
  ButtonGroup,
} from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';

import { List, ListItem, Typography } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Switch from '@mui/material/Switch';
import axios from 'axios';
import { toast } from 'react-toastify';
import ChatState from '../context/chat/ChatState';

function NestedListItem({ title, children, style, childStyle }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItem
        component={'div'}
        sx={{ borderTop: '1px solid #edf5f3', py: 0, ...style }}
        disableGutters
      >
        <ListItemButton onClick={() => setOpen(!open)}>
          <ListItemText primary={title} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <div style={{ ...childStyle }}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <ListItem>{children}</ListItem>
        </Collapse>
      </div>
    </>
  );
}

function Profile() {
  const authContext = useContext(AuthContext);
  const { user, apiUrl, token, updateUser, whoAmI } = authContext;
  const [refreshed, setRefreshed] = useState(false);
  const [userImage, setUserImage] = useState(user.avatar);
  const [file, setFile] = useState(null);
  const inputRef = useRef(null);
  const [form, setForm] = useState({
    username: user.username,
    first_name: user.first_name,
    last_name: user.last_name,
    phone: user.phone,
    email: user.email,
    password: null,
    image: null,
    notifications: user.notifications ? user.notifications : false,
  });
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    //format phone into only numbers
    if (name === 'phone') {
      value = value.replace(/\D/g, '');
    }
    setForm({ ...form, [name]: value });
  };

  useLayoutEffect(() => {
    whoAmI().then((res) => {

      const client = res.client

      setForm({
        ...form,
        username: client.username,
        first_name: client.first_name,
        last_name: client.last_name,
        phone: client.phone,
        email: client.email,
      });
      setUserImage(client.avatar);
      setRefreshed(true);
    });
    // eslint-disable-next-line
  }, []);

  const handleChangeFile = (e) => {
    const imageMimeType = /image\/(png|jpg|jpeg)/i;

    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      alert('Image mime type is not valid');
      return;
    }
    setFile(file);
  };
  const handleNotifications = (e, x) => {
    setForm({ ...form, notifications: x });
  };
  const commonProps = {
    variant: 'outlined',
    size: 'small',
    sx: {
      // '& :before,& :after': { display: 'none' },
      '& .MuiOutlinedInput-root fieldset': {
        borderColor: 'rgba(255, 160, 160, 0.1)',
        borderRadius: 0,
      },
      '& .Mui-focused': {
        borderColor: 'secondary.main',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'secondary.main!important',
        },
      },
    },
  };

  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setUserImage(result);
          setForm({ ...form, image: result });
        }
      };
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
    // eslint-disable-next-line
  }, [file]);
  const cancelImageChange = () => {
    setForm({ ...form, image: null });
    setFile(null);
    setUserImage(user.avatar);
    inputRef.current.value = null;
  };
  const handleUpdateProfile = async () => {
    const formData = new FormData();
    formData.append('first_name', form.first_name);
    formData.append('last_name', form.last_name);
    formData.append('phone', form.phone);
    if (form.password) formData.append('password', form.password);
    if (form.image) formData.append('image', await (await fetch(form.image)).blob(), `${form.first_name}_avatar.png`);


    await axios
      .post(`${apiUrl}/v1/client/update-profile`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const { data } = res;
        toast.success(data.data.message);
        updateUser(data.data);
      })
      .catch((res) => {
        if (res.response.status === 401) {
          authContext.logout();
          toast.error('Session expired, please login again');
        } else {
          console.error(res);
          const responseData = res.response.data;
          //show errors[] or message
          if (responseData.errors) {
            const errorMessages = [];
            for (const key in responseData.errors) {
              if (responseData.errors.hasOwnProperty(key)) {
                const fieldErrors = responseData.errors[key];
                for (let i = 0; i < fieldErrors.length; i++) {
                  errorMessages.push(fieldErrors[i]);
                  toast.error(fieldErrors[i]);
                }
              }
            }
            // toast.error(errorMessages.join('\n'));
          }
          else {
            toast.error(responseData.message);
          }
        }
      });
  };
  if (!refreshed) {
    return <CircularProgress />;
  }
  return (
    <ChatState  >
      <Container
        maxWidth={false}
        sx={{
          px: { xs: '27px', md: '50px' },
        }}
      >
        <>
          <Box
            sx={{
              color: '#181818',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              justifyContent: { md: 'center' },
              fontSize: '.9em',
              position: 'relative',
              px: 0,
              pt: 3,
              pb: '81px',
            }}
          >
            <Container
              maxWidth={false}
              sx={{
                position: 'relative',
                px: '0!important',
              }}
            >
              <OpenAiNavbar />
            </Container>
          </Box>
          {/* User Avatar */}
          <Grid container maxWidth={'md'} sx={{ mx: 'auto', marginTop: { xs: '0', md: '5rem' } }}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Avatar
                  alt={form.first_name}
                  src={userImage}
                  variant="square"
                  sx={{
                    width: 150,
                    height: 150,
                    borderRadius: '8px',
                    '& .MuiAvatar-img': {
                      objectFit: 'contain',
                      backgroundColor: '#edf5f3',
                    },
                  }}
                ></Avatar>
                <ButtonGroup>
                  <IconButton
                    color="inherit"
                    aria-label="upload picture"
                    component="label"
                  >
                    <input
                      hidden
                      accept="image/*"
                      onChange={handleChangeFile}
                      type="file"
                      ref={inputRef}
                    />
                    <PhotoCamera />
                  </IconButton>
                  {userImage !== user.avatar && (
                    <IconButton
                      onClick={cancelImageChange}
                      color="error"
                      component="label"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </ButtonGroup>

                {/* User First_name and Last_name under the avatar image */}
                <Typography
                  component="h1"
                  variant="h1"
                  sx={{
                    fontSize: '2em',
                    lineHeight: '1.25em',
                    fontWeight: '800',
                    textTransform: 'capitalize',
                    color: 'secondary.main',
                    marginBottom: '0.3em',
                  }}
                >
                  {user.first_name} {user.last_name}
                </Typography>
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontSize: '1.1em',
                    lineHeight: '1.25em',
                    fontWeight: '500',
                    color: '#6F8BA4',
                    textTransform: 'lowercase',
                    marginBottom: '1.8em',
                    fontFamily: '"Poppins", sans-serif',
                  }}
                >
                  <span>@</span>
                  {user.username}
                </Typography>

                {/* User Details inputs */}
                <Box
                  sx={{
                    mx: 'auto',
                    width: '100%',
                    maxWidth: '490px',
                  }}
                >
                  <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    sx={{ marginBottom: '1.5em' }}
                  >
                    <ListItem>
                      <ListItemText primary="First Name" />
                      <TextField
                        {...commonProps}
                        value={form.first_name}
                        name="first_name"
                        onChange={handleChange}
                      />
                    </ListItem>
                    <ListItem sx={{ borderTop: '1px solid #edf5f3' }}>
                      <ListItemText primary="Last Name" />
                      <TextField
                        {...commonProps}
                        value={form.last_name}
                        name="last_name"
                        onChange={handleChange}
                      />
                    </ListItem>
                    <ListItem sx={{ borderTop: '1px solid #edf5f3' }}>
                      <ListItemText primary="Phone Number" />
                      <InputMask
                        mask="(999) 999 9999"
                        onChange={handleChange}
                        value={form.phone}
                      >
                        {() => (
                          <TextField {...commonProps} name="phone" />
                        )}
                      </InputMask>
                    </ListItem>
                    {/* <ListItem sx={{ borderTop: '1px solid #edf5f3' }}>
                    <ListItemText primary="User Name" />
                    <TextField
                      {...commonProps}
                      value={form.username}
                      name="username"
                      onChange={handleChange}
                    />
                  </ListItem> */}
                    <ListItem sx={{ borderTop: '1px solid #edf5f3' }}>
                      <ListItemText primary="Email" />
                      <TextField
                        {...commonProps}
                        name="email"
                        type="email"
                        value={form.email}
                        onChange={handleChange}
                      />
                    </ListItem>
                    <NestedListItem title="Reset Password">
                      <ListItemText primary="New Password" />
                      <TextField
                        {...commonProps}
                        type="password"
                        name="password"
                        value={form.password ? form.password : ''}
                        onChange={handleChange}
                      />
                    </NestedListItem>
                    <ListItem sx={{ borderTop: '1px solid #edf5f3' }}>
                      <ListItemText primary="Notifications" />
                      <Switch
                        checked={form.notifications}
                        onChange={handleNotifications}
                      />
                    </ListItem>
                  </List>
                  <Button
                    variant="contained"
                    sx={{
                      fontFamily: '"Poppins", sans-serif',
                      textTransform: 'uppercase',
                      fontSize: '1.3em',
                      width: '100%',
                      borderRadius: '50px',
                      fontWeight: '500',
                      color: 'white.main', borderColor: 'transparent',
                      backgroundColor: 'secondary.main',
                      '&:hover': {
                        backgroundColor: 'secondary.light',
                        borderColor: 'transparent'
                      },
                    }}
                    onClick={handleUpdateProfile}
                  >
                    UPDATE
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      </Container>
    </ChatState>
  );
}

export default Profile;
