import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { CircularProgress, ListItemText } from '@mui/material';

import ChatContext from '../../../context/chat/chatContext';

export default function ShowLogsByDate({ hiddenChatBox }) {
    const chatContext = useContext(ChatContext);
    const { filtered, selectPreviousLog, chatDate, loading } = chatContext;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [date, setDate] = useState('');

    //use effect to change the rendered date when the chatDate array changes
    useEffect(() => {
        setDate('')
    }, [chatDate])

    const handleChange = (event) => {
        setDate(event.target.textContent)
    };

    //format the date received from the database
    const formatChatDate = chatDate ? chatDate.map((date) => {
        const inputDate = new Date(date);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, "0");
        const day = String(inputDate.getDate()).padStart(2, "0");
        const hours = String(inputDate.getHours()).padStart(2, "0");
        const minutes = String(inputDate.getMinutes()).padStart(2, "0");
        const seconds = String(inputDate.getSeconds()).padStart(2, "0");
        const amOrPm = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 || 12;

        return `Created @ ${year}-${month}-${day} ${formattedHours}:${minutes}:${seconds} ${amOrPm}`;
    })
        : ''

    return (
        <>
            {
                //set the button to loading state when the previous logs are being fetched
                loading ? (<CircularProgress
                    color="secondary"
                    size="30px"
                />)
                    : (
                        <Button
                            size="small"
                            variant="outlined"
                            fullWidth
                            sx={{
                                borderRadius: 3, color: 'secondary.main',
                                borderColor: 'secondary.main', textTransform: 'none', fontSize: '0.9rem',
                                marginX: { xs: 4, sm: 1 },
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                justifyContent: { xs: 'center', sm: hiddenChatBox() ? "center" : "flex-start" },
                                '&:hover': {
                                    backgroundColor: 'secondary.main',
                                    color: 'white.main',
                                },
                            }}
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            disabled={filtered && filtered.length === 0 ? true : false}
                        >
                            {date === '' && (formatChatDate && formatChatDate.length === 0)
                                ? 'No previous conversation'
                                :
                                date === '' && (formatChatDate && formatChatDate.length !== 0)
                                    ? formatChatDate[0]
                                    : date}
                        </Button >
                    )}
            <Menu
                sx={{
                    '& .MuiMenu-paper': {
                        border: '1px solid #d3d4d5',
                    },
                }}
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {filtered && filtered.map((log, index) => (
                    <ListItemText key={index} fontSize='small'
                        onClick={(e) => {
                            selectPreviousLog(log[0].conversation_id); handleChange(e);
                            handleClose();
                        }}
                        //set the conversation id to this field to be used in the selectPreviousLog function
                        primary={log ? `Created @ ${log[0].conversation_id}`
                            : 'No previous conversation'} primaryTypographyProps={{
                                display: 'none',
                            }}
                        //set the formatted date to this field to be rendered on the button
                        secondary={formatChatDate ? formatChatDate[index]
                            : 'No previous conversation'} secondaryTypographyProps={{
                                color: '#000',
                            }}
                        //on hover change background color
                        sx={{
                            '&:hover': {
                                backgroundColor: 'secondary.main',
                            },
                        }}
                    />
                ))}
            </Menu >
        </>
    );

}
