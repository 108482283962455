import React, { useContext, useEffect, } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { AccountCircle } from '@mui/icons-material';
import { Box, Button, Divider, Grid } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

import ChatContext from '../../../context/chat/chatContext';
import AuthContext from '../../../context/auth/AuthContext';

import AiCategories from './AiCategories';

function CustomDrawer({ handleDrawerToggle }) {

  const location = useLocation();

  // Determine which link to render based on the current location
  const isOnProfilePage = location.pathname === '/profile';

  const { user } = useContext(AuthContext);
  const chatContext = useContext(ChatContext);
  const { filterLogs, assistant, previousLogs, } = chatContext;
  const [anchorElUser, setAnchorElUser] = React.useState(null);


  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  //when the user selects a different assistant, filter the logs by the assistant name
  useEffect(() => {
    filterLogs(assistant)
  }, [previousLogs])

  const isSelected = (path) => {
    return location.pathname === path;
  }

  return (
    <>
      <Box sx={{ flexGrow: 0, py: 1 }}>
        <Menu
          sx={{ mt: '80px', }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem
            //redirect to chat page
            component={RouterLink}
            disabled={!isOnProfilePage}
            to='/'
            sx={{
              width: '200px !important',
              color: isSelected('/') ? 'white.main' : '#7c7c7c',
              '&:hover': {
                color: 'secondary.main'
              },
              bgcolor: isSelected('/') ? 'secondary.light' : 'transparent',
            }}>
            <Typography textAlign="center">Chat Page</Typography>
          </MenuItem>
          <MenuItem
            //redirect to profile page
            disabled={isOnProfilePage}
            component={RouterLink}
            to='/profile'
            sx={{
              width: '200px !important',
              color: isSelected('/profile') ? 'white.main' : '#7c7c7c',
              '&:hover': {
                color: 'secondary.main'
              },
              bgcolor: isSelected('/profile') ? 'secondary.light' : 'transparent',
            }}>
            <Typography textAlign="center">Profile</Typography>
          </MenuItem>
          <Divider width='75%' sx={{ marginLeft: '20px' }} />
          <MenuItem onClick={() => window.logout()} sx={{
            width: '200px !important', color: '#7c7c7c',
            '&:hover': {
              color: 'secondary.main',
            },
          }} >
            <LogoutIcon fontSize="small" sx={{ marginRight: '10px' }} />
            <Typography textAlign="center">Logout</Typography>
          </MenuItem>
        </Menu>
      </Box>
      <Grid container direction='column' gap={6}>
        <Grid item sx={{ textAlign: 'center', color: 'white.main' }}>
          <Typography variant="h6" sx={{ my: 2 }}>
            OpenAi
          </Typography>
          <Button onClick={handleOpenUserMenu}
            size="large"
            sx={{
              p: 0, display: {
                xs: 'inline-grid', background: 'transparent', color: 'white',
                '& .MuiButton-outlinedSizeLarge': {
                  padding: 0,
                  border: 'none'
                },
                '&  MuiButton-outlinedPrimary': {
                  borderColor: 'transparent',
                },
                '& .MuiSvgIcon-root': {
                  color: 'white', fontSize: '2rem'
                }
              }
            }}
            endIcon={<AccountCircle />}
          >
            {user ? user.first_name : null}
          </Button >
        </Grid>
        <Grid item padding={4} sx={{ p: 4, color: 'white.main' }} display={isOnProfilePage ? 'none' : 'flex'}>
          <AiCategories handleDrawerToggle={handleDrawerToggle} />
        </Grid>
      </Grid>
    </>
  )
}
export default CustomDrawer;