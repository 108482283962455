import React, { useEffect, useMemo } from 'react';
import { createContext, useReducer } from 'react';
import authReducer from './PageReducer';
import axios from 'axios';
import { toast } from 'react-toastify';
import debounce from 'lodash.debounce';
const PageContext = createContext();

export const PageProvider = ({ children }) => {
	const initialState = {
		page: null,
		loading: true,
	};

	const [state, dispatch] = useReducer(authReducer, initialState);
	useEffect(() => {
		if (!state.page) {
			debounceIdentifyMethod();
		}
		return () => {
			debounceIdentifyMethod.cancel();
		};
		// eslint-disable-next-line
	}, []);

	// Get page details
	// first we get the reseller data . Then in AUTH we get client data upon login
	const identify = async () => {
		const identifier = window.location.hostname;
		    // const identifier = 'wholewayassist.com';
		const apiUrl = process.env.REACT_APP_API_URL
		axios
			.get(`${apiUrl}/v1/reseller?domain=${identifier}`)
			.then((res) => {
				if (res) {
					dispatch({
						type: 'SET_PAGE',
						payload: res.data.data.reseller,
					});
				}
			})
			.catch((res) => {
				const responseData = res.response.data;
		
				//show errors[] or message
				if (responseData.errors) {
					const errorMessages = [];
					for (const key in responseData.errors) {
						if (responseData.errors.hasOwnProperty(key)) {
							const fieldErrors = responseData.errors[key];
							for (let i = 0; i < fieldErrors.length; i++) {
								toast.error(fieldErrors[i]);
							}
						}
					}

				}
				else {
					toast.error(responseData.message);
				}
				setLoading(false);
			});

		return;
	};
	const debounceIdentifyMethod = useMemo(
		() => debounce(identify, 300),
		// eslint-disable-next-line
		[]
	);
	// Set loading
	const setLoading = (value) =>
		dispatch({ type: 'SET_LOADING', payload: value });

	return (
		<PageContext.Provider
			value={{
				loading: state.loading,
				page: state.page,
			}}
		>
			{children}
		</PageContext.Provider>
	);
};

export default PageContext;
