import { useContext, } from 'react';

import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography, } from '@mui/material';
import { ChatBubbleOutline } from '@mui/icons-material';

import Avatar3 from '../../../assets/icons/ajuna-assistant.png';
import ChatContext from '../../../context/chat/chatContext';
import ShowLogsByDate from './ShowLogsByDate';

const AssistantCard = ({ StartNewChat, hiddenChatBox }) => {
    const chatContext = useContext(ChatContext);
    const { assistant, category, clearAllLogs, messages } = chatContext;

    return (
        <Grid item>
            <Card sx={{ borderRadius: 3, boxShadow: 3, }}>
                <CardMedia
                    component="img"
                    alt="assistant"
                    image={Avatar3}
                    sx={{
                        backgroundRepeat: 'no-repeat',
                        background: 'linear-gradient(160deg, rgba(255, 255, 255, 0.1) 40%, rgba(113, 92, 247, 0.25) 40%);'
                    }}
                />
                <CardContent
                    sx={{
                        marginTop: { xs: 0, sm: 2 }, paddingBottom: { xs: 1 },
                        display: hiddenChatBox() ? "block" : "none"
                    }}>
                    <Typography gutterBottom variant="body2" color="secondary" noWrap>
                        {assistant ? assistant : "Please select an assistant"}
                    </Typography>
                    <Typography gutterBottom variant="body2" color="text.secondary">
                        AI Assistant
                    </Typography>
                    <Typography gutterBottom variant="body2" color="secondary" noWrap>
                        {category ? category : "Please select a category"}
                    </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center', flexDirection: 'column', marginX: { xs: 4, sm: 1 }, }} >
                    <Button
                        size="small"
                        variant="outlined"
                        fullWidth
                        disabled={assistant === null || category === null}
                        onClick={messages ? messages.length === 0 ? StartNewChat : clearAllLogs : StartNewChat}
                        sx={{
                            marginTop: 1,
                            borderRadius: 3, color: 'secondary.main',
                            borderColor: 'secondary.main', textTransform: 'none', fontSize: '0.9rem',
                            textWrap: 'nowrap',
                            marginX: { xs: 4, sm: 1 },
                            '&:hover': {
                                backgroundColor: 'secondary.main',
                                color: 'white.main',
                            },
                        }}
                    >
                        <ChatBubbleOutline fontSize='small' sx={{ marginRight: '4px' }} />
                        {messages ? messages.length === 0 ? "Start New Chat" : "Clear Chat Log " : "Start New Chat"}
                    </Button >
                </CardActions>
                <Typography gutterBottom variant="body2" color="secondary" textAlign='center' noWrap>
                    Select Chat (Last 30 days)
                </Typography>
                <CardActions sx={{ justifyContent: 'center', paddingBottom: 4, }} >

                    <ShowLogsByDate
                        //show the previous logs by date
                        hiddenChatBox={hiddenChatBox}
                    />
                </CardActions>
            </Card >
        </Grid >
    );
};

export default AssistantCard;
