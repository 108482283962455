import React, { useContext } from 'react';
import AuthContext from '../context/auth/AuthContext';
import HomeAuth from './auth/HomeAuth';
import { Box } from '@mui/material';
import TempPassword from "./TempPassword";
import OpenAi from './chat/OpenAi'

function Home() {
  const { user, verified } = useContext(AuthContext);

  return (
    <Box

      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
      }}
    >
      {!user ? (
        <HomeAuth></HomeAuth>
      ) : (
        <>
          {verified ? (<OpenAi></OpenAi>) : <TempPassword />}
        </>
      )}
    </Box>
  );
}

export default Home;
