import React, { useReducer, useContext, useEffect } from 'react';
import ChatContext from './chatContext';
import chatReducer from './chatReducer';
import axios from 'axios';
import AuthContext from '../auth/AuthContext';

const ChatState = (props) => {
	const initialState = {
		categories: null,
		loading: false,
		error: null,
		selectedAssistants: null,
		previousLogs: [],
		assistant: null,
		filtered: null,
		category: null,
		previousLog: null,
		chatDate: null,
		conversationID: null,
		previousLogconversationID: null,
		messages: [],
	};

	const [state, dispatch] = useReducer(chatReducer, initialState);
	const { apiUrl, token } = useContext(AuthContext);
	const identifier = window.location.hostname;
	// const identifier = https://cgptpwa.gosocialdev.eu/;


	// Get all categories and assistants from the API and set them in the state
	useEffect(() => {
		axios
			.get(`${apiUrl}/open-ai/assistants`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((response) => {
				dispatch({
					type: 'SET_CATEGORIES',
					payload: response.data,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	// select the assistants from the the individual selected category
	const setSelectedAssistants = (category) => {
		dispatch({
			type: 'SET_SELECTED_ASSISTANTS',
			payload: category.assistants,
		});
	};

	//Get all user's previous logs
	const getUserLogs = async () => {
		try {
			const res = await axios.get(
				`${apiUrl}/open-ai/user-logs`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
					params: {
						client_domain: identifier,
					},
				}
			);
			//extract the keys from the user logs object
			const keys = Object.keys(res.data);
			const values = Object.values(res.data);
			//create a new array of objects with the keys and values extracted above 
			const userLogsArray = keys.map((key, index) => {
				return {
					key: key,
					value: values[index],
				};
			});
			
			//Set all previous logs for all assistants in the state
			dispatch({
				type: 'GET_LOGS',
				payload: userLogsArray,
			});
		} catch (error) {
			console.log(error);
		}
	};

	// Set the assistant selected by the user
	const setAssistant = (assistant) => {
		dispatch({
			type: 'SET_ASSISTANT',
			payload: assistant,
		});
	}

	// set the category selected by the user
	const setCategory = (category) => {
		dispatch({
			type: 'SET_CATEGORY',
			payload: category.category,
		});
	}

	//Filter the previous logs for each individual assistant
	const filterLogs = (assistant) => {
		dispatch({
			type: 'FILTER_LOGS',
			payload: assistant,
		});
	}
	//Select the previous log to be displayed in the chat box using the conversation id
	const selectPreviousLog = (id) => {
		dispatch({
			type: 'PREVIOUS_LOG',
			payload: id,
		});
	}
	//Set the chat messages to be displayed in the chat box
	const setChatMessages = (messages) => {
		dispatch({
			type: 'SET_CHAT_MESSAGES',
			payload: messages,

		});
	}
	//Set the chat id to new ID if the user selects a new conversation, otherwise set it to the previous log conversation id
	const setChatID = (id) => {
		dispatch({
			type: 'SET_CHAT_ID',
			payload: id,

		});
	}
	//Clear all logs from the state
	const clearAllLogs = () => {
		dispatch({
			type: 'CLEAR_LOGS',
		});
	}
	//Set the loading state
	const setLoading = (value) =>
		dispatch({
			type: 'SET_LOADING',
			payload: value
		});

	return (
		<ChatContext.Provider
			value={{
				categories: state.categories,
				selectedAssistants: state.selectedAssistants,
				previousLogs: state.previousLogs,
				assistant: state.assistant,
				filtered: state.filtered,
				category: state.category,
				previousLog: state.previousLog,
				chatDate: state.chatDate,
				conversationID: state.conversationID,
				previousLogconversationID: state.previousLogconversationID,
				messages: state.messages,
				loading: state.loading,
				getUserLogs,
				setSelectedAssistants,
				setAssistant,
				filterLogs,
				setCategory,
				selectPreviousLog,
				clearAllLogs,
				setChatMessages,
				setChatID,
				setLoading,
			}}
		>
			{props.children}
		</ChatContext.Provider>
	);
};

export default ChatState;
