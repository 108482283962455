import React, { useState, useContext } from 'react';

import {
  // Link,
  Grid,
  Box,
  Paper,
  Typography,
  FormControl,
  TextField,
  alpha,
} from '@mui/material';
import { Email, Person, Phone, Message } from '@mui/icons-material';

import axios from 'axios';
import { toast } from 'react-toastify';
import AuthContext from '../../context/auth/AuthContext';
import LoadingButton from '@mui/lab/LoadingButton';
import Legal from '../../components/Legal';
import { useTheme } from '@emotion/react';

function Contact() {
  //reseller code
  //const identifier = process.env.REACT_APP_IDENTIFIER;
  const identifier = window.location.hostname;
  const { setLoading, apiUrl, loading, user } =
    useContext(AuthContext);
  const theme = useTheme();

  //set state for fields
  const [state, setState] = useState({
      first_name: '',
      last_name: '',
      phone:'',
      email: '',
      message: '',
  });

  //set the html input fields
  const inputFieldValues = [
    {
      name: 'first_name',
      label: 'First Name *',
      id: 'first_name',
      icon: <Person sx={{ mr: { xs: 1, xl: 2 } }} />,
      required: true,
      halfRow: true,
    },
    {
      name: 'last_name',
      label: 'Last Name *',
      id: 'last_name',
      icon: <Person sx={{ mr: { xs: 1, xl: 2 }, opacity: 0 }} />,
      required: true,
      halfRow: true,
    },
    {
      name: 'email',
      label: 'Email *',
      id: 'email',
      icon: <Email sx={{ mr: { xs: 1, xl: 2 } }} />,
      required: true,
    },
    {
      name: 'phone',
      label: 'Phone Number',
      id: 'phone',
      icon: <Phone sx={{ mr: { xs: 1, xl: 2 } }} />,
      required: false,
      type: 'phone',
    },
    {
      name: 'message',
      label: 'Your Message *',
      id: 'message',
      icon: <Message sx={{ mr: { xs: 1, xl: 2 } }} />,
      multiline: true,
      textAreaHeight: {
        xs: 'calc(100% - 3px)!important',
        md: 'calc(100% - 14px)!important',
      },
      required: true,
      height: { xs: '90px!important' },
      mb: { xs: '39px', md: '19px' },
      borderRadius: { xs: '25px', md: '50px' },
      overflow: 'auto!important',
    },
  ];
  //set state for errors
  const [errors, setErrors] = useState([]);
  const regexEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  //set error messages for fields
  const validate = () => {
    setErrors([]);
    let newErrors = [];

    Object.keys(state).forEach((x) => {
      if (!state[x]) {
        newErrors.push({
          name: x,
          error:
            'The ' +
            x.charAt(0).toUpperCase() +
            x.slice(1) +
            ' field is Required',
        });
      }
    });
    //email format validation
    if (state.email && !regexEmail.test(state.email)) {
      newErrors.push({
        name: 'email',
        error: 'The Email field is invalid (e.g. email@domain.com).',
      });
    }
    //for each error , show toast
    // newErrors.forEach((x) => {
    //   toast.error(x.error);
    // });

    return newErrors;
  };

  //validate & trigger ajax on submit
  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validate();

    if (errors.length > 0) {
      setErrors(errors);
    } else {
      sendForm();

      //reset Form and inputs
      setTimeout(function () {
        event.target.reset();
        setState({
          ...state,
          first_name: '',
          last_name: '',
          email: '',
          message: '',
            phone: '',
        });
      }, 1000);
    }
  };

  //save the new fields state on change
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  // send data  trough ajax
  const sendForm = async () => {
    //trigger loading icon
    setLoading(true);
    axios
      .post(`${apiUrl}/v1/reseller/contact-us-email`, {
        domain: identifier,
        email: state.email,
        first_name: state.first_name,
        last_name: state.last_name,
        phone: state.phone,
        message: state.message,
      })
      .then((res) => {
        // console.log(res);
        toast.success(
          'Your message was sent. Thank you for contacting us!'
        );
        setLoading(false);
      })
      .catch((res) => {
        res = res.response;
        if (res.data.errors) {
          let errorsArray = [];
          //for each result error, show toast
          Object.entries(res.data.errors).forEach((x) => {
            let fieldName = x[0];
            let fieldValueArray = x[1];
            fieldValueArray.forEach((err) => {
              toast.error(err);
            });
            errorsArray.push({
              name: fieldName,
            });
            setErrors(errorsArray);
          });
        } else {
          toast.error(res.data.message);
        }
        setLoading(false);
      });

    return;
  };
  const btnStyle = {
    fontFamily: 'Lato',
    fontWeight: '400',
    fontSize: { xs: '16px', md: '35px' },
    lineSeight: '100%',
    letterSpacing: '0.24px',
    borderRadius: '50px',
    height: { xs: '64px', md: '98px' },
    textTransform: 'none',
  };

  const TextFieldStyle = {
    '& .MuiInputBase-root': {
      backgroundColor: '#fff',
      borderRadius: '50px',
      borderColor: '#fff',
      height: { xs: '50px', md: '70px' },
      pl: { xs: '22px', md: '30px' },
      alignItems: 'flex-end',
      pb: { xs: '7px', md: '15px' },
      '& legend': { display: 'none' },
      '& .MuiOutlinedInput-notchedOutline': {
        top: 0,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '&:hover svg': {
        color: 'secondary.main',
      },
    },
    '& .MuiFormLabel-root': {
      top: { xs: '10px', md: '14px' },
      left: { xs: '8px', md: '17px' },
    },
    '& .Mui-focused': {
      borderColor: 'transparent',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent!important',
      },
    },
    '& .MuiInputBase-input': {
      p: '0',
    },
    '& .MuiInputLabel-root' : {
      color:'secondary.main'
    },
    '& .MuiFormHelperText-root.Mui-error' : {
      color:{sm:'error.light', xs:'error.dark'}
    },
  };
  // const mainPadding = user ? '68px' : '0px;';
  return (
    <Grid
      container
      sx={{
        flexGrow: '1',
        position: 'relative',
        alignItems: 'stretch',
        height: '100%',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'flex-end',
      }}
    >
      <Grid
        item
        xs={12}
        sm={4}
        md={6}
        lg={7}
        sx={{
          position: { xs: 'fixed', sm: 'relative' },
          bottom: { xs: '0px', sm: 'initial' },
          width: { xs: '100%', sm: 'initial' },
          backgroundImage:
            'url(https://source.unsplash.com/random?laptop)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light'
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: { xs: '100vh', sm: 'auto' },
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        lg={5}
        component={Paper}
        elevation={6}
        square
        sx={{
          position: 'relative',
          zIndex: 1,
          background: {
            xs: `linear-gradient(180deg,  ${alpha(
              theme.palette.primary.main,
              0
            )} 0%, ${theme.palette.primary.lighter} 70%)`,

            sm:`radial-gradient(circle, ${alpha(
              theme.palette.primary.main,
              0
            )} 0%, ${theme.palette.primary.light} 0%, 
            ${theme.palette.primary.lighter} 100%)`,
          },
          boxShadow: 'none',
          display: 'flex',
          paddingTop: { xs: '22vh', sm: '0' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            flexGrow: '1',
            height: '100%',
          }}
        >
          <Box
            sx={{
              mx: { xs: '20px', md: 4 },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: { xs: 'flex-end', sm: 'center' },
              flexGrow: '1',
            }}
          >
            <Typography
              component="h1"
              color="white.main"
              variant="h1"
              sx={{
                mt: 3,
                color: 'white.main',
                fontSize: { xs: '40px', md: '53px' },
                lineHeight: '1.25em',
                fontWeight: '800',
              }}
              mb={{ xs: 1, md: 4 }}
            >
              Contact Us
            </Typography>
            <Typography
              component="p"
              color={'white.secondary'}
              sx={{
                maxWidth: '500px',
                fontSize: { xs: '16px', md: '18px' },
                textShadow: 'rgb(0 0 0) 2px 0px 3px',
                textAlign: 'center',
              }}
              mb={{ xs: '20px', md: 4 }}
            >
              Have a question? Our team is here for you and ready to
              help.
            </Typography>
            <Box
              sx={{
                maxWidth: '400px',
                textAlign: 'left',
              }}
            ></Box>
            <Box>
              <form autoComplete="off" onSubmit={handleSubmit}>
                {/* generate input fields */}
                <Grid container spacing="10px">
                  {inputFieldValues.map((inputFieldValue, index) => {
                    return (
                      <Grid
                        item
                        key={index}
                        xs={12}
                        sm={
                          'halfRow' in inputFieldValue &&
                          inputFieldValue
                            ? 6
                            : 12
                        }
                      >
                        <FormControl fullWidth margin="none">
                          <TextField
                            id={inputFieldValue.id}
                            name={inputFieldValue.name}
                            label={inputFieldValue.label}
                            value={state[inputFieldValue.name]}
                            type={inputFieldValue.type ?? 'text'}
                            onChange={handleChange}
                            InputProps={{
                              startAdornment: inputFieldValue.icon,
                            }}
                            multiline={
                              inputFieldValue.multiline ?? false
                            }
                            fullWidth
                            error={
                              errors.find(
                                (x) => x.name === inputFieldValue.name
                              )
                                ? true
                                : false
                            }
                            helperText={
                              inputFieldValue.required &&
                              errors.find(
                                (x) => x.name === inputFieldValue.name
                              )
                                ? errors.find(
                                    (x) =>
                                      x.name === inputFieldValue.name
                                  ).error
                                : null
                            }
                            sx={[
                              TextFieldStyle,
                              {
                                mb: { xs: '10px', md: 2 },
                                '& .MuiInputBase-root': {
                                  height: inputFieldValue.height,
                                  borderRadius:
                                    inputFieldValue.borderRadius,
                                },
                                textarea: {
                                  height:
                                    inputFieldValue.textAreaHeight,
                                  overflow: inputFieldValue.overflow,
                                },
                                '& .MuiInputBase-root svg': {
                                  marginBottom: inputFieldValue.mb,
                                },
                              },
                            ]}
                          />
                        </FormControl>
                      </Grid>
                    );
                  })}
                </Grid>

                <LoadingButton
                  variant="contained"
                  type="submit"
                  color="primary"
                  loading={loading}
                  fullWidth
                  sx={[btnStyle, { mb: { xs: '20px', md: 3 }, 
                  color: 'white.main', borderColor: 'transparent', 
                  backgroundColor:'secondary.main', 
                '&:hover': {
                  backgroundColor: 'secondary.light',
                  borderColor: 'transparent'
              }, 
                }]}
                >
                  Send Message
                </LoadingButton>
              </form>
            </Box>
          </Box>
          <Box
            sx={{
              my: 1,
              mx: 3,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              justifyContent: 'end',
            }}
          >
            <Legal />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Contact;
