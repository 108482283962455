const chatReducer = (state, action) => {

    switch (action.type) {
        // get all categories and assistants from the API and set them in the state
        case "SET_CATEGORIES":
            return {
                ...state,
                categories: action.payload,
            };
        // select the assistants from the the individual selected category
        case "SET_SELECTED_ASSISTANTS":
            return {
                ...state,
                selectedAssistants: action.payload,
            };
        // get all user's previous logs
        case "GET_LOGS":
            return {
                ...state,
                previousLogs: action.payload,
                loading: false,
            };
        // set the assistant selected by the user
        case "SET_ASSISTANT":
            return {
                ...state,
                assistant: action.payload,
                previousLog: null,
                loading: true,
            };
        // set the category selected by the user
        case "SET_CATEGORY":
            return {
                ...state,
                category: action.payload,
            };

        // filter the previous logs by the assistant selected by the user
        case "FILTER_LOGS":
            return {
                ...state,
                //filter the logs using the complete array of logs and the assistant name
                filtered: state.previousLogs.map(log => log.value).filter(name => name[0].assistant_name === action.payload),
                //set the chat date to be rendered in the select chat list
                chatDate: state.previousLogs.map(log => log.value).filter(name => name[0].assistant_name === action.payload).map(log => log[0].created_at),
            };

        // select the previous log to be displayed in the chat box using the conversation id
        case "PREVIOUS_LOG":
            return {
                ...state,
                //set the previous log array which will be rendered in the chat box              
                previousLog: state.filtered ? state.filtered.filter(logs => logs[0].conversation_id === action.payload)[0] : null,
                //set the previous log conversation id 
                previousLogconversationID: state.filtered ? state.filtered.map(logs =>
                    logs.filter(log => log.conversation_id === action.payload)).map(log =>
                        log.length > 0 ? log[0].conversation_id : '')
                    //select from this new array only the value that is not empty
                    .filter(value => value !== '')
                    //and make the result not to be an array but a string
                    .join('')
                    : 'no previous log',
                messages: [],

            };

        // set the chat messages to be displayed in the chat box, both in case of a new conversation or a previous log
        case "SET_CHAT_MESSAGES":
            return {
                ...state,
                messages: [action.payload.message, ...state.messages],
            };
        // set the chat id to new ID if the user selects a new conversation, otherwise set it to the previous log conversation id
        case "SET_CHAT_ID":
            return {
                ...state,
                conversationID: action.payload,
            };

        // clear all logs from the state to start a new conversation
        case "CLEAR_LOGS":
            return {
                ...state,
                messages: [],
                conversationID: null,
                previousLog: null,
                previousLogconversationID: null,
            };

        // set the loading state to true or false
        case "SET_LOADING":
            return {
                ...state,
                loading: action.payload,
            };

        default:
            return state;
    }
}

export default chatReducer;