import * as React from 'react';
import { useContext } from 'react';

import { Container, Grid } from '@mui/material';

import ChatContext from '../../../context/chat/chatContext';

import UserChatMessage from './UserChatMessage';

export default function UserLogs() {

	const chatContext = useContext(ChatContext);
	const { messages } = chatContext;

	return (
		<Grid container
			sx={{ height: '100%', paddingY: '1rem !important' }} >
			<Grid item container sx={{ paddingBottom: '1rem !important', overflow: 'auto', height: '100%', }} >
				<Container direction='column' sx={{
					maxWidth: '1500px !important',
					height: '100%', overflow: 'auto',
					paddingLeft: '0 !important ',
					marginLeft: '0 !important',
					paddingBottom: '1rem !important	',
				}} >
					{//map through the messages array and display the messages in the chatbox formatted in the UserChatMessage component
						messages.length >= 1 && messages[0].map((log, index) =>
							<UserChatMessage
								key={index}
								messages={{
									message: log.message,
									sender: log.role,
									conversation_id: log.conversation_id,
									index: index,
									time: log.time,
								}}
							/>
						)}
				</Container>
			</Grid>
		</Grid>

	);
}
