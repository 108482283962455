import React, { useContext, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Container, Divider, } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { AccountCircle } from '@mui/icons-material';
import CustomDrawer from './CustomDrawer';

import LogoutIcon from '@mui/icons-material/Logout';

import AuthContext from '../../../context/auth/AuthContext';

const drawerWidth = 240;

function OpenAiNavbar() {
  const { user } = useContext(AuthContext);

  const location = useLocation();

  // Determine which link to render based on the current location
  const isOnProfilePage = location.pathname === '/profile';

  const [anchorElUser, setAnchorElUser] = useState(null);

  const [mobileOpen, setMobileOpen] = useState(false);
  const [isNavbarSlidUp, setIsNavbarSlidUp] = useState(false);

  // slide up the navbar when the user clicks on the menu icon
  const handleDrawerToggle = () => {
    if (!mobileOpen) {
      setIsNavbarSlidUp(true);
    }
    // wait for the navbar to slide up before opening the drawer
    setTimeout(() => {
      setMobileOpen(!mobileOpen);
      setIsNavbarSlidUp(!isNavbarSlidUp);
    }, 300);
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const isSelected = (path) => {
    return location.pathname === path;
  }



  return (
    <>
      <AppBar position="fixed" sx={{
        borderBottomRightRadius: '16px', borderBottomLeftRadius: '16px', height: '72px',
        background: "linear-gradient(160deg,#715cf7,#715cf7,#7b56ed,#9a8aff)",
        transform: isNavbarSlidUp ? 'translateY(-100%)' : 'none',
        transition: 'transform 0.3s ease-in-out',
      }}>
        <Container maxWidth="xxl" sx={{ paddingX: { sm: '2rem', md: '4rem !important', } }} >
          <Toolbar disableGutters>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: 'none' }, order: { xs: 1, md: 0 } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              color={'white !important'}
              sx={{ flexGrow: 1 }}
            >
              {/* logo to be inserted here */}
              OpenAi
            </Typography>
            <Box sx={{ flexGrow: 0, }}>
              <Button onClick={handleOpenUserMenu}
                size="large"
                sx={{
                  p: 0, display: {
                    xs: 'none', md: 'flex', background: 'transparent', color: 'white',
                    '& .MuiButton-outlinedSizeLarge': {
                      padding: 0,
                      border: 'none'
                    },
                    '&  MuiButton-outlinedPrimary': {
                      borderColor: 'transparent',
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'white', fontSize: '2rem'

                    }
                  }
                }}
                endIcon={<AccountCircle />}
              >
                <Typography textAlign="center" sx={{ textTransform: 'capitalize' }}>
                  {user ? user.first_name : null}
                </Typography>
              </Button>
              <Menu
                sx={{
                  mt: '60px',
                  '& .MuiMenu-paper': { borderRadius: ' 10px !important' },

                }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  //redirect to chat page
                  component={RouterLink}
                  disabled={!isOnProfilePage}
                  to='/'
                  sx={{
                    width: '200px !important',
                    color: isSelected('/') ? 'white.main' : '#7c7c7c',
                    '&:hover': {
                      color: 'secondary.main'
                    },
                    bgcolor: isSelected('/') ? 'secondary.light' : 'transparent',
                  }}>
                  <Typography textAlign="center">Chat Page</Typography>
                </MenuItem>
                <MenuItem
                  //redirect to profile page
                  disabled={isOnProfilePage}
                  component={RouterLink}
                  to='/profile'
                  sx={{
                    width: '200px !important',
                    color: isSelected('/profile') ? 'white.main' : '#7c7c7c',
                    '&:hover': {
                      color: 'secondary.main'
                    },
                    bgcolor: isSelected('/profile') ? 'secondary.light' : 'transparent',
                  }}>
                  <Typography textAlign="center">Profile</Typography>
                </MenuItem>
                <Divider width='75%' sx={{ marginLeft: '20px' }} />
                <MenuItem
                  //logout from page
                  onClick={() => window.logout()} sx={{
                    width: '200px !important', color: '#7c7c7c',
                    '&:hover': {
                      color: 'secondary.main',
                    },
                  }} >
                  <LogoutIcon fontSize="small" sx={{ marginRight: '10px' }} />
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar >
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box', width: drawerWidth, borderTopRightRadius: '20px',
              borderBottomRightRadius: '20px', background: "linear-gradient(160deg,#715cf7,#715cf7,#7b56ed,#9a8aff)"
            },
          }}
        >
          <CustomDrawer handleDrawerToggle={handleDrawerToggle} />
        </Drawer>
      </nav>
    </>
  )
}

export default OpenAiNavbar;