import { useEffect, useRef } from 'react';

import { Avatar, CardContent, Grid, Typography } from '@mui/material';

import avatar from '../../../assets/icons/ajuna-assistant.png';

export default function UserChatMessage({ messages }) {

  //format the time of the message
  const formatChatTime = (messages) ? (() => {
    const inputDate = new Date(messages.time);
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");
    const formattedHours = hours % 12 || 12;

    return ` ${formattedHours}:${minutes}`;
  })
    : '';
  // scroll to the bottom of the chatbox when a new message is sent
  const chatContainerRef = useRef(null);
  const scrollToBottom = () => {
    chatContainerRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  }
  useEffect(scrollToBottom, [messages.sender]);


  return (
    <CardContent
      sx={{ padding: 0, marginY: 1 }}>
      <Grid item container>
        <Grid item container wrap="nowrap" alignItems="end" gap={1}
          //if this is the beginning of a fresh conversation, don't display the user message since it is empty and triggers the API call
          display={messages.index === 0 && messages.sender === 'user' ? 'none' : 'flex'}
          sx={{ justifyContent: messages.sender === 'user' ? 'flex-end' : 'flex-start', }}>
          {messages.sender === 'user' ? (
            <Grid item order={2}>
              <Avatar
                sx={{
                  backgroundColor: '#0d0d0d',
                  borderRadius: '50%',
                }}
              >
                Me
              </Avatar>
            </Grid>
          ) : (
            <Grid item>
              <Avatar
                alt="Angela"
                src={avatar}
                sx={{ borderRadius: '50%' }}
              ></Avatar>
            </Grid>
          )}
          <Grid item
            ref={chatContainerRef}
            sx={{
              background:
                messages.sender === 'user' ? "linear-gradient(160deg,#715cf7,#715cf7,#c28ce6)" : '#f1f1f1',
              color: messages.sender === 'user' ? '#fff' : '#7c7c7c',
              borderRadius: 2,
              padding: 2,
              position: 'relative',
            }}>
            <Typography
              variant="body2"
              sx={{
                lineHeight: 1.5,
                marginBottom: '0.3rem',
              }}
            >
              {messages.message}
            </Typography>
            <Typography
              sx={{
                position: 'absolute',
                right: messages.sender === 'assistant' ? '0.5rem' : 'auto',
                left: messages.sender === 'user' ? '0.5rem' : 'auto',
                bottom: '0.5rem',
                opacity: '0.75',
                lineHeight: '1em',
                fontSize: '0.65em',
              }} >
              {//if the time prop is less than 5, it comes from the API and is already formatted, otherwise it comes from the database and needs to be formatted
                messages.time.length === 5 ? messages.time : formatChatTime()}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </CardContent >
  );
}
